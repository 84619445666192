/* Importing the provided CSS */
@import '../../styles/global.css'; /* Assuming you have global styles */

/* Container for Left and Right Sections */
.container {
    display: flex;
    width: 100%;
    max-width: 1400px;
    background-color: #f9f9f9; /* Lighter background */
    border-radius: 50px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

/* Left Sidebar */
.sidebar {
    width: 35%;
    padding: 30px 20px;
    border-right: 1px solid #ddd; /* Lighter border */
    background-color: #fff; /* White background */
    color: #333; /* Darker text color */
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the logo and text horizontally */
    justify-content: center; /* Center the logo and text vertically */
    text-align: center; /* Center text */
    margin-bottom: 20px;
}

.logo {
    width: 100px;
    height: auto;
    margin-bottom: 10px; /* Adjust spacing between the logo and text */
}


.header h1 {
    margin-bottom: 10px;
    font-size: 28px;
    color: #000;
    text-align: center;
}

.header p {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
}

.sidebar h1 {
    margin-bottom: 20px;
    color: 333; /* Maroon */
}

.sidebar p {
    margin-bottom: 15px;
    line-height: 1.5;
    color: #333; /* Lighter text for better readability */
}

.imageGallery {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

.placeholder {
    width: 100%;
    height: auto;
    border-radius: 5px;
    object-fit: cover;
}

.downloadButton {
    display: inline-block;
    padding: 10px 20px;
    background-color: #748c72; /* Accent color */
    color: #fff;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
}

.downloadButton:hover {
    background-color: #5a7a58;
}

/* Right Content (Quill Editor) */
.editorSection {
    width: 65%;
    padding: 30px 20px;
    background-color: #f7f7f7; /* Light background for better readability */
    color: #333; /* Darker text for readability */
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 90vh;
}

.editorSection h2,
.editorSection h3 {
    margin-bottom: 15px;
    color: #333; /* Maroon accent */
}

.editorSection p {
    margin-bottom: 20px;
    line-height: 1.6;
    color: #333; /* Lighter text */
}

/* Resume Example Image */
.resumeExample {
    width: 30%;
    height: auto;
    border-radius: 5px;
    margin-bottom: 20px;
}

/* Call to Action */
.callToAction {
    margin-top: 30px;
    text-align: center;
}

.emailInput {
    width: 80%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc; /* Lighter border */
    border-radius: 5px;
    font-size: 14px;
    background-color: #fff; /* Light background */
    color: #333; /* Darker text for readability */
}

.signUpButton {
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    background-color: #748c72; /* Accent color */
    color: #fff;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.signUpButton:hover {
    background-color: #5a7a58;
}

.betaNote {
    margin-top: 10px;
    font-size: 12px;
    color: #888;
}

/* Contact Information */
.contactInfo {
    margin-top: 30px;
    text-align: center;
}

.contactInfo a {
    color: #748c72;
    text-decoration: none;
}

.contactInfo a:hover {
    color: #5a7a58;
}

/* Disclaimer */
.disclaimer {
    margin: 30px 0px 30px 0px;
    text-align: center;
    font-size: 12px;
    color: #888;
}

.disclaimer a {
    color: #748c72;
    text-decoration: none;
}

.disclaimer a:hover {
    color: #5a7a58;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .container {
        flex-direction: column;
    }

    .sidebar,
    .editorSection {
        width: 100%;
    }

    .downloadButton {
        max-width: 100%;
    }
}
