/* src/pages/Home/Home.module.css */
.home {
    padding: 100px 40px 60px 40px;
    background-color: #ffffff;
    min-height: 100vh;
  }
  
  .hero {
    text-align: center;
    margin-bottom: 60px;
  }
  
  .hero h1 {
    font-size: 3rem;
    color: #000000;
  }
  
  .hero p {
    font-size: 1.2rem;
    color: #ae395d; /* Maroon */
  }
  
  .apps {
    display: flex;
    justify-content: space-around;
    gap: 40px;
    flex-wrap: wrap;
  }
  
  .appCard {
    background-color: #f9f9f9;
    border: 1px solid #eaeaea;
    border-radius: 12px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    width: 45%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .appCard img {
    width: 100%;
    border-radius: 12px;
    margin-bottom: 20px;
  }
  
  .appCard h2 {
    color: #800000; /* Maroon */
    margin-bottom: 10px;
  }
  
  .appCard p {
    color: #333333;
    margin-bottom: 20px;
  }
  
  .button {
    text-decoration: none;
    background-color: #ae395d; /* Maroon */
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 25px;
    transition: background-color 0.3s ease;
    text-align: center;
  }
  
  .button:hover {
    background-color: #8a2c48;
  }
  